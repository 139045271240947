.fc-toolbar-chunk > .btn-group {
  height: 50px;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #dcf0ff;
}

.fc-day-today {
  background-color: #dcf0ff;
}

.fc-daygrid-event {
  font-size: .7em;
}