$dark-slate-blue  : #203460;
$dark-indigo      : #112652;
$cornflower       : #5a7dfd;
$black            : #353535;
$grey             : #d8d7d7;
$brownish-grey    : #6a6969;
$warm-grey       : #707070;
$white            : #FFFFFF;
$white-three      : #e4e4e4;
$color-grey-white : #FAFAFA;
$color-green      : #B9D4E0;
$dull-green       : #63a15e;
$tealish          : #1ebead;
$viridian         : #259a49;
$orange-yellow    : #ff9f00;
$warm-pink        : #f25767;
$very-light-purple : #fce4e9;
$very-light-purple: #fce4e9;
$blue-grey        : #7884a0;
$pale-grey        : #f6f5fa;
$pale-grey-two    : #ebe9f3;
$iris             : #7260be;
$perrywinkle      : #b1a4e5;
$light-navy-blue  : #33297c;
$french-blue      : #3c54ad;
$faded-blue      : #677cc8;

//Fonts
$global    : 'Nunito Sans', sans-serif;

.alert {
  color: $orange-yellow;
  padding: 0;
  display: inline;
  margin-right: 5px;
}

h2.section-title {
  font-size: 22px;
  font-weight: 900;
}

.btn {
  border: none;
  padding: 3px 15px;
  font-size: 14px;
  font-weight: 600;

  @include media-breakpoint-up(lg){
    font-size: 15px;
    padding: 3px 30px;
  }

  &.btn-blue {
    background: $cornflower;
    color: $white;
    border-radius: 25px;
  }

  &.full {
    width: 100%;
  }

  &-icon {
    border: none;
    background: none;

    i {
      color: $cornflower;
    }
  }

  &.btn-delete-select {
    background: transparent;
    border: none;
    color: $warm-pink;
    padding: 0;
    position: absolute;
    right: 4px;
    top: 4px;
  }

  &.btn-date {
    width: 100%;
    border: 1px solid $grey;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
}

.table-list {
  thead{
    tr {
      th {
        font-size: 14px;
        font-weight: 600;
        color: $black;
        padding: 5px 10px;
        background: $pale-grey-two;
        text-align: center;
        border: 1px solid #b7b7b7;

        @include media-breakpoint-up(md) {
          padding: 10px 10px;
        }

        .icon-sort {
          margin-left: 5px;

          i {
            font-size: 10px;
          }
        }

        &.wi-40 {
          @include media-breakpoint-up(md) {
            width: 60%;
          }
          @include media-breakpoint-up(lg) {
            width: 40%;
          }
        }

        &.w-200 {
          @include media-breakpoint-up(md) {
            width: 200px;
          }

          @include media-breakpoint-up(lg) {
            width: 400px;
          }
        }
      }
    }
  }

  .MuiTableCell-root {
    font-family: $global;
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      background-color: $white;
      &:hover,
      &.Mui-selected{
        background-color: $color-green;
      }

      &.bold {
        td {
          font-weight: 700;
        }
      }
      &:nth-child(even) {
        background-color: $pale-grey;

        &:hover,
        &.Mui-selected{
          background-color: $color-green;
        }
      }

      td {
        border: none;
        color: $black;
        padding: 5px 10px;
        vertical-align: baseline;
        font-size: 14px;
        text-align: center !important;
        border: 1px solid #b7b7b7;

        @include media-breakpoint-up(md) {
          padding: 15px 10px;
        }

        &.custom-td {
          min-width: 200px;
        }

        &.text-blue {
          color: $cornflower;
        }

        &.text-red {
          color: $cornflower;
        }

        &.text-number {
          font-weight: 600;
        }

        .alert {
          background: $orange-yellow;
          color: $black;
          padding: 5px;
          width: 100px;
          display: block;
        }
        .danger {
          border-radius: 0.25rem;
          background: #dc3545;
          padding: 5px;
          width: 100px;
          display: block;
          color: $white;
        }
      }
    }
  }

  &.plain {
    .MuiTableBody-root {
      .MuiTableRow-root {
        background-color: transparent;

        &:hover,
        &.Mui-selected{
          background-color: transparent;
        }

        &:nth-child(even) {
          background-color: transparent;

          &:hover,
          &.Mui-selected{
            background-color: transparent;
          }
        }

        td {
          border: none;
          color: $black;
          padding: 5px 10px;
        }
      }
    }
  }
}

.Toastify__toast {
  font-family: $global;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;

  &.Toastify__toast--error {
    background: $warm-pink;
  }

  &.Toastify__toast--success {
    background: $tealish;
  }
}

.btn-icon {
  padding: 0 7px;
  border-radius: 100%;
  background: $tealish;
  width: 26px;
  height: 26px;
  display: inline-block;
  text-align: center;

  &.project-detail {
    position: absolute;
    right: 15px;
    top: 0;
  }
  i {
    color: $white;
    font-size: 10px;
    line-height: 26px;
  }

  &:first-child {
    background: $cornflower;
  }

  &:last-child {
    background: $warm-pink;
  }

  &.green {
    background: $tealish;
  }
}

.table-wrapper {
  .MuiPopover-paper-230,
  .jss229,
  .jss499,
  .jss774 {
    overflow-y: scroll;
    top: 100px !important;
    max-height: 80% !important;
  }

  .MuiMenu-paper-519 {

  }
}
